import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AnimationComponent from './components/load/Animation';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const Home = React.lazy(() => import('./Home'));
const AboutUs = React.lazy(() => import('./pages/about/page'));
const Discover = React.lazy(() => import('./pages/discover/page'));
const Project = React.lazy(() => import('./pages/projects/page'));
const ContactUs = React.lazy(() => import('./components/ContactUs'));



const router = createBrowserRouter([
  {
    path: "/",
    element: ( 
    <Suspense fallback={<AnimationComponent/>}>
      <Home/>
    </Suspense> ),
  },
  {
    path: "/about",
    element: ( 
      <Suspense fallback={<AnimationComponent/>}>
        <AboutUs/>
      </Suspense> ),
  },
  {
    path: "/discover",
    element: ( 
      <Suspense fallback={<AnimationComponent/>}>
        <Discover/>
      </Suspense> ),
  },
  {
    path:"/contact-us",
    element: ( 
      <Suspense fallback={<AnimationComponent/>}>
        <ContactUs/>
      </Suspense> ),
  },
  {
    path: "/projects/:id",
    element: ( 
      <Suspense fallback={<AnimationComponent/>}>
        <Project/>
      </Suspense> ),
  }
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);


