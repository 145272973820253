import Lottie  from 'lottie-react';
import animationData from './laith-logo.json';

const AnimationComponent = () => {
    return (
      <div style={{backgroundColor: "black", width: '100%', height: "100vh"}}>
        <Lottie 
          animationData={animationData}
          loop={true} 
          autoplay={true} 
        />
      </div>
    );
  }

  export default AnimationComponent